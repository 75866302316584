import React, { useEffect, useState } from 'react';
import FrameCheckout from 'ekaubamaja-ui/lib/Frames/Checkout';
import useCartSummary from '../../hooks/useCartSummary';
import FramePage from 'components/frames/FramePage';
import Block from 'ekaubamaja-ui/lib/Components/Block';
import LayoutSidebar from 'ekaubamaja-ui/lib/Layouts/LayoutSidebar';
import LayoutColumnPrimary from 'ekaubamaja-ui/lib/Layouts/LayoutSidebarPrimary';
import LayoutColumnSecondary from 'ekaubamaja-ui/lib/Layouts/LayoutSidebarSecondary';
import { formatPriceLocale } from '../../helpers/priceUtils';
import Summary, { ISummaryMeta } from 'ekaubamaja-ui/lib/Applications/Checkout/Components/Summary';
import { closeOverlay, openOverlay } from 'data/actions/overlays';
import { useDispatch } from 'react-redux';
import CartOverlay from 'components/Checkout/components/CartOverLay';
import { IShippingMethod } from 'components/Checkout/interfaces/IShippingMethod';
import ShippingContent from 'components/Checkout/components/ShippingContent';
import SubmitBasketRequest, {
    ISubmitBasketData,
    ISubmitBasketResponse,
} from 'components/Checkout/requests/SubmitBasketRequest';
import { useMutation } from 'redux-query-react';
import { HttpStatusCode } from '../../enums/HttpStatusCode';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster';
import { ShippingMethodCodes } from 'components/Checkout/ShippingMethodCodes';

interface IProps {
    config: {
        pageTitle: string;
        logoTitle: string;
        logoUrl: string;
        agreementContents: string;
        isSubscribed: boolean;
        labels: {
            purchaseSummary: string;
            checkout: string;
            backToStore: string;
            requiredFields: string;
            comment: string;
            lookAtOrder: string;
            order: string;
            orderDetails: string;
            submit: string;
            cancel: string;
            notEnoughPermissions: string;
            shipping: string;
            shippingAddress: string;
            registerNewsletter: string;
            agreeToTerms: string;
            cartNotOverMinimumTotal: string;
            signingNotificationLabel: string;
            packLabelPDFLabel: string;
            fileTypeMustBePDFLabel: string;
            readingPackLabelFailed: string;
            confirmationLabels: {
                overLayTitle: string;
                confirmSubmitting: string;
                smartId: string;
                mobileId: string;
                phoneNumber: string;
                personalCode: string;
                sign: string;
                downloadFile: string;
                verificationCode: string;
                signingFailed: string;
                idCard: string;
                signOrder: string;
            };
        };
        shippingMethods: IShippingMethod[];
    };
}

const Checkout: React.FunctionComponent<IProps> = (props) => {
    const { config } = props;
    const { labels } = config;
    const { orderNumber, cartTotals, submitAction, minOrderSum } = useCartSummary();
    const dispatch = useDispatch();

    const [cartMetaItems, setCartMetaItems] = useState<ISummaryMeta[]>([]);
    const [selectedShippingMethod, setSelectedShippingMethod] = useState<IShippingMethod>(config.shippingMethods[0]);
    const [shippingComment, setShippingComment] = useState('');
    const [grandTotal, setGrandTotal] = useState(0);
    const [registerNewsletter, setRegisterNewsletter] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [packLabel, setPackLabel] = useState<File | undefined>();
    const [{}, submitBasket] = useMutation((data: ISubmitBasketData) => SubmitBasketRequest(data));

    useEffect(() => {
        if (cartTotals) {
            const cartItems: ISummaryMeta[] = [];

            cartItems.push(
                { label: cartTotals.subTotal.label, sum: formatPriceLocale(cartTotals.subTotal.value) },
                { label: cartTotals.vatTotal.label, sum: formatPriceLocale(cartTotals.vatTotal.value) },
            );

            if (cartTotals.discountTotal.value < 0) {
                cartItems.push({
                    label: cartTotals.discountTotal.label,
                    sum: formatPriceLocale(cartTotals.discountTotal.value, undefined, true),
                });
            }

            cartItems.push({
                label: labels.shipping,
                sum: formatPriceLocale(selectedShippingMethod.price ?? ''),
            });

            let calculatedGrandTotal = cartTotals.grandTotal.value;

            if (selectedShippingMethod.price) {
                calculatedGrandTotal += selectedShippingMethod.price;
            }

            cartItems.push({
                label: cartTotals.grandTotal.label,
                sum: formatPriceLocale(calculatedGrandTotal),
                total: true,
            });

            setGrandTotal(calculatedGrandTotal);
            setCartMetaItems(cartItems);
        }
    }, [cartTotals, selectedShippingMethod]);

    const setShippingMethod = (method: IShippingMethod) => {
        setSelectedShippingMethod(method);
    };

    const getPackLabelContents = (packLabel: File) =>
        new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(packLabel);

            fileReader.onload = () => resolve(fileReader.result.split(',')[1]);
            fileReader.onerror = reject;
        });

    const handleSubmitBasket = async () => {
        setIsSubmitting(true);

        let packLabelPDFContent: string | undefined = undefined;

        if (packLabel && selectedShippingMethod.methodCode === ShippingMethodCodes.dropshipping) {
            try {
                packLabelPDFContent = (await getPackLabelContents(packLabel)) as string;
            } catch (err) {
                Toaster.addToast({ intent: 'danger', text: labels.packLabelPDFLabel });
                return;
            }
        }

        submitBasket({
            shippingMethodCode: selectedShippingMethod.methodCode,
            shippingComment: shippingComment,
            packLabelPDFContent: packLabelPDFContent,
            extraData: {
                registerNewsletter: registerNewsletter,
            },
        }).then((res) => {
            if (res.status === HttpStatusCode.CREATED) {
                window.location.assign(res.body.url);
            } else {
                Toaster.addToast({ intent: 'danger', text: res.body.message ?? '' });
                setIsSubmitting(false);
            }
        });
    };

    if (!cartTotals) {
        return null;
    }

    return (
        <FrameCheckout
            title={config.logoTitle}
            logoHref={'/'}
            logoURL={config.logoUrl}
            wrapHeader={true}
            login={undefined}
        >
            <FramePage title={config.pageTitle}>
                <CartOverlay
                    CartContent={<Summary title={`${labels.order} ${orderNumber}`} meta={cartMetaItems} />}
                    orderDetails={labels.orderDetails}
                />
                <Block>
                    <LayoutSidebar
                        openSidebarLabel={labels.lookAtOrder}
                        openSidebarIcon={'cart'}
                        openSidebarAddOn={formatPriceLocale(grandTotal)}
                        openSidebar={() => dispatch(openOverlay({ name: 'summary' }))}
                    >
                        <LayoutColumnPrimary
                            title={labels.checkout}
                            titleActions={[
                                {
                                    title: labels.backToStore,
                                    className: 'has-text-color-primary',
                                    icon: 'arrow2-left',
                                    type: 'anchor',
                                    href: '/',
                                    layout: 'link',
                                },
                            ]}
                        >
                            <ShippingContent
                                shippingMethods={config.shippingMethods}
                                setSelectedShippingMethod={setShippingMethod}
                                selectedShippingMethod={selectedShippingMethod}
                                shippingComment={shippingComment}
                                setShippingComment={setShippingComment}
                                submitOrder={handleSubmitBasket}
                                setPackLabel={setPackLabel}
                                minOrderSum={minOrderSum}
                                requiredFieldsLabel={labels.requiredFields}
                                commentLabel={labels.comment}
                                submitLabel={labels.submit}
                                cancelLabel={labels.cancel}
                                notEnoughPermissionsLabel={labels.notEnoughPermissions}
                                confirmationLabels={labels.confirmationLabels}
                                shippingAddressLabel={labels.shippingAddress}
                                submitAction={submitAction}
                                registerNewsletter={registerNewsletter}
                                setRegisterNewsletter={setRegisterNewsletter}
                                registerNewsletterLabel={labels.registerNewsletter}
                                isSubscribedToNewsletter={config.isSubscribed}
                                agreementContents={config.agreementContents}
                                agreeToTermsLabel={labels.agreeToTerms}
                                cartNotOverMinimumTotalLabel={labels.cartNotOverMinimumTotal}
                                signingNotificationLabel={labels.signingNotificationLabel}
                                isSubmitting={isSubmitting}
                                packLabelPDFLabel={labels.packLabelPDFLabel}
                                fileTypeMustBePDFLabel={labels.fileTypeMustBePDFLabel}
                            />
                        </LayoutColumnPrimary>
                        <LayoutColumnSecondary title={labels.purchaseSummary}>
                            <Summary title={`${labels.order} ${orderNumber}`} meta={cartMetaItems} />
                        </LayoutColumnSecondary>
                    </LayoutSidebar>
                </Block>
            </FramePage>
        </FrameCheckout>
    );
};

export default Checkout;
